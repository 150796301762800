type HotjarConfiguration =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      id: number;
    };

type MixpanelConfiguration =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      token: string;
    };

type UnleashConfiguration =
  | { enabled: false }
  | {
      enabled: true;
      url: string;
      clientKey: string;
      refreshInterval: number;
      appName: string;
    };

export interface ApplicationProperties {
  hotjar: HotjarConfiguration;
  mixpanel: MixpanelConfiguration;
  settings: string[];
  login: string[];
  unleash: UnleashConfiguration;
}

const config: Record<string, ApplicationProperties> = {
  "http://localhost:3000": {
    hotjar: {
      enabled: false,
    },
    mixpanel: {
      enabled: false,
    },
    settings: ["password", "oidc"],
    login: ["password", "oidc"],
    unleash: {
      enabled: false,
    },
  },
  "https://kantz-dev.hooloovoo.dev": {
    hotjar: {
      enabled: true,
      id: 3066214,
    },
    mixpanel: {
      enabled: true,
      token: "fadbbdc2b518572a6e84e98a0d4313f3",
    },
    settings: ["password", "oidc"],
    login: ["password", "oidc"],
    unleash: {
      enabled: false,
    },
  },
  "https://kantz.hooloovoo.rs": {
    hotjar: {
      enabled: true,
      id: 3066200,
    },
    mixpanel: {
      enabled: true,
      token: "fadbbdc2b518572a6e84e98a0d4313f3",
    },
    settings: [],
    login: ["oidc"],
    unleash: {
      enabled: false,
    },
  },
};

export const Configuration: ApplicationProperties =
  config[document.location.origin] ?? config["https://kantz.hooloovoo.rs"]!;

export function isAccountSettingsVisible(): boolean {
  return Configuration.settings.length > 0;
}
