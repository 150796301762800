import { Box } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { format } from "date-fns";
import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../../../../modules/authentication/hooks";
import {
  ChartPoint,
  getChartData,
  OccupancyResponse,
} from "../../../../../modules/booking/api";
import { useBackofficeLocations } from "../../../../state";
import { useReservationDate } from "../../state";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DISPLAY_DATE_FORMAT: string = "dd.MM.yyyy";

const DefaultJsDaysIndex = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
};

function useGetChartData(): UseQueryResult<OccupancyResponse> {
  const { organization } = useOrganizationMembership();
  const { dateRange } = useReservationDate();
  const { location } = useBackofficeLocations();
  // Hardcoded for now as part of https://hooloovoo.atlassian.net/browse/AMT2-725
  // Should be removed when backoffice comes alive
  const countryCode = "SRB";
  const locationId = location?.location?.id;
  return useQuery(
    [
      "getChartData",
      organization?.id,
      dateRange.startDate,
      dateRange.endDate,
      locationId,
      countryCode,
    ],
    () => {
      return getChartData(
        organization?.id,
        dateRange.startDate,
        dateRange.endDate,
        locationId,
        countryCode
      );
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60_000,
    }
  );
}

export const OccupancyChartAverage: React.FC = () => {
  const { t } = useTranslation("analytics");

  const chartData = useGetChartData()?.data;
  const chartLines = chartData?.chartLines || [];
  const dataSets: any[] = [];
  for (let i = 0; i < chartLines.length; i++) {
    const chartLine = chartLines[i];
    const toAggregate: { [key: number]: { repeats: number; sum: number } } = {};

    Object.values(DefaultJsDaysIndex).forEach(
      (day) => (toAggregate[day] = { repeats: 0, sum: 0 })
    );

    chartLines[i].points.forEach((point) => {
      const dayOfWeek = new Date(point.date).getDay();
      if (!isWeekend(dayOfWeek) && !isHolidayAndEmpty(point)) {
        toAggregate[dayOfWeek].repeats++;
        toAggregate[dayOfWeek].sum += point.occupied / chartLines[i].capacity;
      }
    });
    const data = Object.entries(toAggregate)
      .sort(sortByWeekday)
      .map(([_, value]) => {
        return Number((value.sum / value.repeats) * 100).toFixed(0);
      });
    const dataSet = buildDataset(
      chartLine.locationName,
      data,
      getUniqueColor(i)
    );
    dataSets.push(dataSet);
  }
  return buildChartBody(
    chartLines,
    getChartLabels([], true, t),
    [...dataSets],
    true
  );
};

export const OccupancyChart: React.FC = () => {
  const { t } = useTranslation("analytics");
  const { dateRange } = useReservationDate();

  const chartData = useGetChartData()?.data;
  const chartLines = chartData?.chartLines || [];
  const filteredDates = filterWeekends(dateRange.startDate, dateRange.endDate);
  const dataSets: any[] = [];

  for (let i = 0; i < chartLines.length; i++) {
    const chartLine = chartLines[i];
    const occupancyData: Record<string, number> = {};
    chartLine.points.forEach((item) => {
      const dateString: string = format(
        new Date(item.date),
        DISPLAY_DATE_FORMAT
      );
      occupancyData[dateString] = Number(
        ((item.occupied / chartLine.capacity) * 100).toFixed(0)
      );
    });
    const data = filteredDates.map(
      (date) => occupancyData[format(date, DISPLAY_DATE_FORMAT)] || 0
    );
    const dataSet = buildDataset(
      chartLine.locationName,
      data,
      getUniqueColor(i)
    );
    dataSets.push(dataSet);
  }
  return buildChartBody(
    chartLines,
    getChartLabels(filteredDates, false, t),
    [...dataSets],
    false
  );
};

function buildDataset(label: string, data: any, color: string) {
  return {
    label: label,
    data: data,
    backgroundColor: [color],
    borderColor: [color],
    borderWidth: 1,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${(tooltipItem.y * 100).toFixed(2)}%`;
        },
      },
    },
  };
}

function buildChartBody(
  chartLines: any,
  labels: any,
  datasets: any,
  aggregated: boolean
) {
  const data = { labels, datasets };
  return (
    <Box
      sx={() => ({
        width: "75%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
      })}
    >
      {chartLines && chartLines.length > 0 && !aggregated && (
        <Line data={data} options={getChartOptions()} />
      )}
      {chartLines && chartLines.length > 0 && aggregated && (
        <Bar data={data} options={getChartOptions()} />
      )}
    </Box>
  );
}

function getChartLabels(data: Date[], aggregated: boolean, t: any) {
  if (aggregated) {
    return [
      t("days-of-week.monday"),
      t("days-of-week.tuesday"),
      t("days-of-week.wednesday"),
      t("days-of-week.thursday"),
      t("days-of-week.friday"),
    ];
  } else {
    return data?.map((date) => format(date, DISPLAY_DATE_FORMAT));
  }
}

function getChartOptions(): any {
  return {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += `: ${context.parsed.y}%`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        suggestedMax: 100,
      },
    },
  };
}

function filterWeekends(startDate: Date, endDate: Date): Date[] {
  const date = new Date(startDate.getTime());
  const dates: Date[] = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates.filter((date) => date.getDay() !== 0 && date.getDay() !== 6);
}

function getUniqueColor(index: number) {
  const hue = index * 137.508; // Use the golden angle to spread out colors
  const saturation = 80;
  const lightness = 60;
  return `hsl(${hue % 360},${saturation}%,${lightness}%)`;
}

function sortByWeekday(day1: any, day2: any): number {
  if (day1 === "1") return -1;
  if (day2 === "1") return 1;
  return parseInt(day1) - parseInt(day2);
}

function isWeekend(day: number): boolean {
  return day === 0 || day === 6;
}

function isHolidayAndEmpty(point: ChartPoint) {
  return point.isHoliday && point.occupied === 0;
}
